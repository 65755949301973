import Vue from 'vue';
import Store from '../js/store.js';
import Storage from '../js/storage.js';

export default {
  start: {
    text: `
    The following questions are going to be very similar and this is on purpose. Every emotion <em>feels</em> different but the introspection process remains largely the same.
    `,
    buttons: { 'Next': 'focus', },
    before() {
      if (! Storage.shown.get('same-process')) {
        Storage.shown.set('same-process', 1);
      } else if (Storage.shown.get('same-process') === 1) {
        Storage.shown.set('same-process', 2);
        return;
      }
      return 'focus';
    }
  },

  focus: {
    text: `Focus for a few moments on your sense of {feeling.noun}. And take a breath or two.`,
    control: { type: 'breathe' },
    buttons: { 'Next': 'howmuch', },
    // todo: always show text for narrated guidance
    narration: 'faq-release-introduction.mp3',
  },

  howmuch: {
    text: `How strong is your {feeling.noun}?`,
    help: `There are no wrong answers here and you don't have to be precise. Just let your finger rest wherever it wants to.`,
    buttons: { 'Next': 'location', },
    control: { type: 'range' },
    before: (card, data) => {
      card.value = Store.intensity[data.feeling.key] || 50;
    },
    after: (card, data) => {
      data.intensity = data.initialIntensity = card.value;
      // xxx: accessing global data here is pretty awful
      Vue.set(Store.intensity, data.feeling.key, card.value);
    },
  },

  location: {
    text: `Can you feel the {feeling.noun} anywhere in particular?`,
    help: `
    Connecting to your body is an age old technique that calms the mind and slows down repetitive thoughts.
    `,
    control: {
      type: 'pills',
      items: 'feeling.locations',
      multiselect: true,
    },
    buttons: { 'OK': 'write_before', 'Skip': 'write_before' },
    before: (card, data) => {
      // skip this step to if there are no locations
      if (! data.feeling.locations) return 'write_before';
    },
    after: (card, data) => {
      // update the location if a location was selected
      if (! card.value) return true;
      data.feeling.location = card.value;
    }
  },

  write_before: {
    text: `So what's going on?`,
    help: `Writing a bit can help bring clarity to your thoughts and feelings and work through whatever is bothering you. Whatever you write will be stored locally on your phone and accessible in the Insights section.`,
    buttons: { 'Next': 'could', 'Skip': 'could' },
    control: {
      type: 'textarea',
    },
    after: (card, data) => {
      if (! card.value) return true;
      Storage.insights.add({
        text: card.value,
        feeling: data.feeling.key,
      });
    }
  },

  could: {
    text: `Could you let some of the {feeling.noun} go?`,
    help: `
      Letting go of emotions simply means no longer holding on to them and allowing them to dissipate.
      It's as natural as breathing out after breathing in or unclenching a clenched fist.
    `,
    buttons: { 'Yes': 'would', 'Not yet': 'holdon' },
    faq: [
      {
        title: `I don't know how to let go.`,
        text: `
          In order to understand what letting go of emotions means, we first need to understand how we're holding on to them. Imagine a small child, maybe a year old. She can't speak yet, and can't form complex thoughts. She cries when she's sad, angry or in pain, she laughs when she's happy and she switches easily and quickly between these emotions. That's how it works until thinking becomes part of the equation.

          Thinking allows us to attach reasons and explanations to our emotions, often in the form of blame we direct at ourselves and our circumstances. These thoughts in turn reinforce the emotions, that would have quickly passed without them. And so we find ourselves stuck in this thinking-feeling loop, often thinking we shouldn't even be feeling this way.
        `,
        narration: 'faq-what-do-you-mean-by-letting-go.mp3'
      },
    ]
  },

  would: {
    text: `Would you let it go?`,
    buttons: { 'Yes': 'when', 'Not yet': 'holdon' },
  },

  when: {
    text: `When?`,
    buttons: { 'Now': 'magic', 'Not yet': 'holdon' },
  },

  holdon: {
    text: `Could you allow yourself to hold on to the {feeling.noun} for a bit?`,
    help: `Sometimes we're not done with a particular feeling and we need to stay with it some more.
    It can be helpful to pretend you're holding the {feeling.noun} in your hand and <strong>squeeze</strong> it as hard as you can.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'holdon2' }
  },

  holdon2: {
    text: `It's OK to hold on. Just take a breath and feel the {feeling.noun} as fully as you can.`,
    help: `Keep squeezing your fist and keep breathing. At some point you might feel the grip starting to loosen which would be a good time to move on.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'couldnow' }
  },

  magic: {
    text: `Now just pause for a moment and take a deep breath. Let the magic work.`,
    help: `You might feel some goosebumps or feel the need to move a little. Do whatever feels best.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'write_after' },
  },

  write_after: {
    text: `How do you feel now?`,
    help: `You may feel a a different emotion or a experience a change in how you perceive your circumstances.`,
    buttons: { 'Next': 'howmuchmore', 'Skip': 'howmuchmore' },
    control: {
      type: 'textarea',
    },
    before: () => {
      return 'howmuchmore';
    },
    after: (card, data) => {
      if (! card.value) return true;
      Storage.insights.add({
        text: card.value,
        feeling: data.feeling.key,
      });
    }
  },

  howmuchmore: {
    text: `How strong is the {feeling.noun} now?`,
    help: `It might grow stronger, stay the same or become weaker. It can take a few rounds of this process to let go of a feeling completely.`,
    buttons: { 'Next': '', 'It\'s gone': 'done' },
    graph_links: { 'Same': 'intensity_same', 'Lower a little': 'intensity_lower_a_little', 'Lower a lot': 'intensity_lower_a_lot', 'Higher': 'intensity_higher', 'It\'s gone': 'done' },
    control: { type: 'range' },
    // faq: [
    //   {
    //     title: `Can {feeling.noun} dissapear completely?`,
    //     text: `
    //     Yes, it can - at least for a while. We're not supposed to get rid of our emotions, they are part of what makes us human. But we can definitely stop holding on to them and let them pass through us and dissipate.
    //     The feeling of completely letting go is magical and is often worth the time.
    //     `,
    //     narration: 'faq-can-it-dissapear-completely.mp3',
    //   },
    // ],
    before: (card, data) => {
      card.value = data.intensity;
    },
    after: (card, data, action) => {
      var intensity = data.intensity;
      data.intensity = card.value;
      // xxx: accessing global data here is pretty awful
      Vue.set(Store.intensity, data.feeling.key, card.value);
      if (action === '') {
        if (card.value < intensity) {
          if (intensity - card.value < 10) {
            return 'intensity_lower_a_little';
          } else {
            return 'intensity_lower_a_lot';
          }
        } else if (card.value === intensity) {
          return 'intensity_same';
        } else if (card.value > intensity) {
          return 'intensity_higher';
        }
      }
    },
  },

  intensity_higher: {
    text: `
      You might be thinking that this isn't working - after all the {feeling.noun} got stronger!
      But this is just part of the journey. As you tap into your {feeling.noun}, you may find there's more of it than you know.
    `,
    buttons: { 'Continue': 'acknowledge', 'Maybe later': 'leave' },
    once: 'acknowledge',
  },

  intensity_lower_a_little: {
    text: `
    You might think this didn't work because it only relieved a little bit of the {feeling.noun}.
    But it <em>did</em> work. You may just need a few rounds of the process to go through it all.
    `,
    buttons: { 'Continue': 'acknowledge', 'Maybe later': 'leave' },
    once: 'acknowledge',
  },

  intensity_lower_a_lot: {
    text: `
      Looks like it's working.
      It can take a few rounds through the process to work through all of the {feeling.noun}, but sometimes just taking the edge off is enough.
    `,
    buttons: { 'Continue': 'acknowledge', 'Maybe later': 'leave'  },
    once: 'acknowledge',
  },

  intensity_same: {
    text: `
      Looks like this didn't work for you at all.
      This usually means the {feeling.noun} is rooted in something deep and you might need some help to work through it, which is beyond the scope of this app.
    `,
    buttons: { 'Give feedback': '__contact__' },
    once: 'acknowledge',
    before(card, data) {
      return 'intensity_lower_a_little';
    }
  },

  acknowledge: {
    text: `Take a few moments to acknowledge this new layer of {feeling.noun}. And take another deep breath.`,
    help: `Sometimes a different feeling arises at this point. It is often a good idea to switch to that instead.`,
    control: { type: 'breathe' },
    buttons: { 'Next': 'couldmore', 'Switch': '__feelings__' },
    faq: [
      {
        title: `What do you mean by <em>layers</em>?`,
        text: `
          Our feelings often come in layers. Sometimes it's layers of the same emotion, and sometimes different emotions. Every layer is a little different and a little deeper then the one above it with different images, different thoughts and different memories.

          You don't have to release all of the layers but doing so will help you find a deeper sense of peace and clarity.
        `,
        narration: 'faq-what-do-you-mean-by-layers.mp3',
      },
    ],
  },

  couldnow: {
    text: `Could you now let it go?`,
    buttons: { 'Yes': 'wouldmore', 'No': 'couldmore_tiny' }
  },

  couldmore: {
    text: `Could you let it go?`,
    buttons: { 'Yes': 'wouldmore', 'No': 'couldmore_tiny'  }
  },

  couldmore_tiny: {
    text: `Could you let just a tiny bit of it go?`,
    buttons: { 'Yes': 'wouldmore', 'No': 'leave' }
  },

  wouldmore: {
    text: `Would you?`,
    buttons: { 'Yes': 'whenmore', 'No': 'leave'  }
  },

  whenmore: {
    text: `When?`,
    buttons: { 'Now': 'magicmore', 'Later': 'leave'  }
  },

  magicmore: {
    text: `Now pause again and take a deep breath. Let the magic work.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'howmuchmore' },
  },

  leave: {
    text: `
    Sometimes {feeling.noun} has such a strong hold on you that you just can't let go.
    This often means there's something in the way you need to focus on first.
    `,
    control: {
      type: 'list',
      items: 'feeling.stuck',
    },
    buttons: { 'Other feelings': '__feelings__', 'Nah, I\'m good': '__exit__' },
    before: (card, data) => {
      global.plausible('Leave', {props:{feeling: data.feeling.name}});
    }
  },

  done: {
    text: `
    <strong>{choice(['Awesome', 'Wonderful', 'Beautiful', 'Nice'])}!</strong>
    Now that you've released the {feeling.noun}, some other feelings may arise.`,
    control: {
      type: 'list',
      items: 'feeling.next',
    },
    buttons: { 'Other feelings': '__feelings__', 'Nah, I\'m good': '__exit__' },
    before: (card, data) => {
      data.intensity = 0;
      // xxx: accessing global data here is pretty awful
      Vue.set(Store.intensity, data.feeling.key, card.value);
      global.plausible('Done', {props:{feeling: data.feeling.name}});
    },
  },

  distraction: {
    text: `Hey... You got distracted, didn't you?<br><It's OK, it happens to everyone.<br>This usually means some doubt or inadequacy creeped in.<br>Just go with the flow to whatever you're feeling right now.`,
    control: {
      type: 'list',
      items: ['doubt', 'anger'],
    },
    buttons: { 'Stay with {feeling.noun}': '__previous__' },
  }
}
